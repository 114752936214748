import { css, FormGroup, Input, Popover, styled, TableSortLabel } from '@mui/material';

import { IThemeDependentStyledComponentProps, IWithCSS } from '/common/models';
import { TextFieldValidator } from '/shared/TextFieldValidator';

interface IVisibleEl {
  show?: boolean;
}

export const HeaderCell = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const SortCell = styled('div')`
  position: relative;
`;

export const SortLabel = styled(TableSortLabel)`
  padding-right: ${(props) => (props.active ? 0 : '5px')};

  & > svg {
    font-size: 1.25em;
    margin: 0;
  }
` as typeof TableSortLabel;

export const CellLabel = styled('span')`
  padding-right: 5px;
  white-space: nowrap;
`;

export const FilterForm = styled('div')`
  padding: 8px;
`;

export const FilterInput = styled(Input)<IThemeDependentStyledComponentProps>`
  padding-right: 3px;

  svg {
    transition: color 100ms linear;

    &:hover {
      color: ${({ $theme }: IThemeDependentStyledComponentProps) => $theme?.palette.action.hover};
    }
  }

  input {
    padding: 10px 3px 10px 7px;
    font-size: 14px;
  }
`;

export const FilterTextFieldValidator = styled(TextFieldValidator)<IThemeDependentStyledComponentProps>`
  padding-right: 3px;
  margin-bottom: 4px;

  svg {
    transition: color 100ms linear;

    &:hover {
      color: ${({ $theme }: IThemeDependentStyledComponentProps) => $theme?.palette.action.hover};
    }
  }

  input {
    padding: 10px 3px 10px 7px;
    font-size: 14px;
  }
`;

export const FilterClearButton = styled('button')<IVisibleEl>`
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: opacity 100ms linear;
  ${({ show }) =>
    !show &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const datePickerCSS = css`
  min-height: 205px;
`;

export const popoverCSS = css`
  & .MuiPopover-paper {
    overflow: visible;
  }
`;

export const PopoverStyled = styled(Popover)`
  ${popoverCSS}
`;

export const FormGroupStyled = styled(FormGroup)<IWithCSS>`
  ${({ css }) => css}
`;
