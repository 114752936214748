import { Chip as MatChip, styled } from '@mui/material';

import { Tags } from '/shared/Tags';

export const QueryRowWrapperStyled = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0 15px;
`;

export const QueryRowStyled = styled('div')`
  display: inline-flex;
  justify-items: center;
  align-items: center;
`;

export const QueryRowActionsStyled = styled('div')`
  float: right;
  ${QueryRowStyled}
`;

export const QueryRowCellStyled = styled('div')`
  display: inline-flex;
  gap: 4px;
  align-items: center;
`;

export const QueryChipsLabelStyled = styled('div')`
  font-size: 12px;
`;

export const QueryChipStyled = styled(MatChip)(
  ({ theme, onClick }) => `
  height: 28px;
  border: 1px solid ${theme.palette.divider};
  background-color: ${theme.palette.background.paper};
  border-radius: 5px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  ${
    onClick !== undefined &&
    `
      cursor: pointer;

      &:hover {
        background-color: ${theme.palette.action.hover};
      }
    `
  }

  & > svg {
    width: 18px !important;
    height: 18px !important;
    &.MuiChip-deleteIcon {
      position: absolute;
      top: -9px;
      right: -9px;
      margin: 0;
      padding: 0;
      & > path {
        fill: #607d8b;
        &:hover {
          fill: #ff5722;
        }
      }
    }
  }

  & > .MuiChip-label {
    display: flex;
    align-items: center;
    padding: 0 7px;
  }
`
);

export const StyledTags = styled(Tags)`
  margin-left: 4px;

  .MuiInputBase-root {
    cursor: pointer;

    .MuiChip-root {
      cursor: pointer;
    }
  }
`;
