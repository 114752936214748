import { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from 'react';
import { styled, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { Schema, ValidateOptions, ValidationError } from 'yup';

import { useEnableBodyScroll } from '/common/useEnableBodyScroll';

export type TextFieldValidatorProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema?: Schema<any> | null;
  alternativeValueToValidate?: unknown;
  errorMsg?: string;
  wrapErrorMsg?: boolean;
  helperTextPosition?: 'block' | 'absolute';
} & {
  validationOptions?: ValidateOptions;
};

export const TextFieldStyled = styled(TextField)<{
  $helperTextPosition: TextFieldValidatorProps['helperTextPosition'];
  $wrapErrorMsg: boolean;
}>`
  position: relative;

  & .MuiFormHelperText-root {
    text-wrap: ${({ $wrapErrorMsg }) => ($wrapErrorMsg ? 'wrap' : 'nowrap')};
  }

  ${({ $helperTextPosition }) =>
    $helperTextPosition === 'absolute' &&
    `
   & .MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
  }
  `}
`;

export const TextFieldValidator: FC<TextFieldValidatorProps> = ({
  value: textFieldValue,
  schema,
  alternativeValueToValidate,
  validationOptions = {},
  helperText,
  errorMsg,
  helperTextPosition = 'absolute',
  wrapErrorMsg = false,
  ...props
}) => {
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState<undefined | string>();
  const didCancel = useRef<boolean>(false);

  const { enableBodyScroll, disableBodyScroll } = useEnableBodyScroll(props.type === 'number');

  useEffect(() => {
    return () => {
      didCancel.current = true;
    };
  }, []);

  useEffect(() => {
    setError(errorMsg);
    if (errorMsg) {
      setDirty(true);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (!schema) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const valueToValidate: unknown = textFieldValue !== undefined ? textFieldValue : alternativeValueToValidate || '';

    schema
      .validate(valueToValidate, validationOptions)
      .then(() => {
        if (error && !didCancel.current) {
          setError(errorMsg);
        }
      })
      .catch((err: ValidationError) => {
        if (!didCancel.current) {
          setError(err.errors[0]);
        }
      });
  }, [textFieldValue, schema, alternativeValueToValidate, validationOptions, error, errorMsg]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!dirty) {
      setDirty(true);
    }

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    disableBodyScroll();
    props.onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    enableBodyScroll();

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  return (
    <TextFieldStyled
      value={textFieldValue}
      variant={'standard'}
      error={!!error && dirty}
      helperText={dirty && error ? error : helperText}
      $helperTextPosition={helperTextPosition}
      $wrapErrorMsg={wrapErrorMsg}
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
