import { FC, ReactNode, SyntheticEvent, useMemo } from 'react';
import { IconButton as MatIconButton, Tooltip } from '@mui/material';

import { IBaseAction } from '/shared/Table';

interface ITableIconAction extends IBaseAction {
  disabled?: boolean;
  tooltip: string | ReactNode;
  onClick: (event: SyntheticEvent) => void;
}

export const TableIconAction: FC<ITableIconAction> = (props) => {
  const {
    ariaLabel,
    icon: Icon,
    onClick,
    tooltip,
    tooltipPlacement,
    disabled = false,
    cy,
    tooltipInteractive = false,
    tooltipLeaveDelay = 0,
    size = 'medium',
    tooltipProps,
  } = props;

  const actionIcon = useMemo(
    () => (
      <MatIconButton
        size={size}
        data-testid={cy}
        aria-label={ariaLabel}
        disabled={disabled}
        onClick={onClick}
        component='div'
      >
        <Icon />
      </MatIconButton>
    ),
    [Icon, ariaLabel, cy, disabled, onClick, size]
  );

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (tooltip) {
    return (
      <Tooltip
        disableFocusListener={true}
        title={tooltip}
        placement={tooltipPlacement || 'top'}
        disableInteractive={!tooltipInteractive}
        leaveDelay={tooltipLeaveDelay}
        {...tooltipProps}
      >
        {actionIcon}
      </Tooltip>
    );
  }

  return actionIcon;
};
