import { Chip, styled } from '@mui/material';
import { Palette } from '@mui/material/styles/createPalette';

import { ThemeProvider } from '/common';
import { IWithCSS, ThemeMode } from '/common/models';

const mainColor = (palette: Palette) =>
  ThemeProvider.getAppropriateStyles(palette.mode as ThemeMode, 'var(--main-color)', palette.text.primary);

export const StyledChip = styled(Chip)<{ colorSchema: IWithCSS['css'] } & IWithCSS>`
  ${({ colorSchema }) => colorSchema}

  font-weight: bold;

  &.MuiChip-sizeSmall {
    height: 20px;
  }

  &.MuiChip-colorPrimary {
    background-color: var(--main-color);
    color: var(--opposite-color);
  }

  &.MuiChip-clickableColorPrimary {
    &:hover,
    &:focus {
      background-color: var(--main-color);
    }
  }

  &.MuiChip-deletableColorPrimary {
    &:focus {
      background-color: var(--main-color);
    }
  }

  &.MuiChip-outlinedPrimary {
    background-color: ${({ theme }) => theme.palette.background.paper} !important;
    border: 1px solid ${({ theme }) => mainColor(theme.palette)};
    color: ${({ theme }) => mainColor(theme.palette)};
  }

  & .MuiChip-deleteIcon {
    color: var(--opposite-color);

    &:hover {
      color: var(--opposite-color);
      opacity: 1;
    }
  }

  &.MuiChip-deleteIconOutlinedColorPrimary {
    .MuiChip-deleteIcon {
      color: ${({ theme }) => mainColor(theme.palette)};
      opacity: 0.7;

      &:hover {
        color: ${({ theme }) => mainColor(theme.palette)};
        opacity: 1;
      }
    }
  }

  &.MuiChip-root {
    ${({ css }) => css}
  }
`;
