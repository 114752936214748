import { SyntheticEvent, useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';

import { ITableState, SelectionCell } from '/shared/Table';

export interface ITableSelectionCellProps<D> extends ITableState<D> {
  initialSelection?: boolean;
  indeterminate?: boolean;
  onSelect: (val: boolean) => void;
  isSelectionDisabled?: boolean;
  disableSelection?: (item: D) => boolean;
  isMasterSelectionDisabled?: boolean;
}

export function GenericTableSelectionCell<D>(props: ITableSelectionCellProps<D>) {
  const {
    initialSelection = false,
    indeterminate = false,
    onSelect,
    isMasterSelectionDisabled,
    isSelectionDisabled,
  } = props;

  const [selected, setSelected] = useState(initialSelection);

  useEffect(() => setSelected(initialSelection), [initialSelection]);

  const handleChange = () => {
    const nSelected = !selected;
    setSelected(nSelected);
    onSelect(nSelected);
  };

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <SelectionCell $isMasterSelectionDisabled={Boolean(isMasterSelectionDisabled)} onClick={handleStopPropagation}>
      <Checkbox
        disabled={isSelectionDisabled}
        checked={selected}
        indeterminate={indeterminate && !selected}
        value={selected.toString()}
        color='primary'
        onChange={handleChange}
      />
    </SelectionCell>
  );
}
