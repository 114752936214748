import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    font-size: 15px;
  }

  & .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 1);
  }

  & .MuiChip-root.Mui-disabled {
    opacity: 1;
  }
`;
