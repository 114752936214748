import { FC, memo, MouseEvent, useMemo } from 'react';
import { css } from '@mui/material';

import { useAppQuery } from '/api';

import { getAppIdFromTag, getStyleProps } from '../utils';

import { ITagChipProps } from './types';
import { StyledChip } from './styles';

export const TagChip: FC<ITagChipProps> = memo(({ tag, onClick, styles, ...props }) => {
  const { tag: tagCSS } = styles || {};
  const appId = useMemo(() => getAppIdFromTag(tag), [tag]);
  const { data: app } = useAppQuery(appId, {
    enabled: appId === undefined,
    retry: false,
    staleTime: Infinity,
  });

  const { label, excluded, mainColor, oppositeColor, variant } = useMemo(() => getStyleProps(tag), [tag]);

  const tagLabel = useMemo(() => (app ? `${label} (${app.name})` : label), [app, label]);

  const handleClick = (e: MouseEvent) => {
    if (onClick) {
      e.stopPropagation();
      onClick(tag);
    }
  };

  const colorSchema = css`
    --main-color: ${mainColor};
    --opposite-color: ${oppositeColor};
    text-decoration: ${excluded ? 'line-through' : 'none'};
  `;

  return (
    <StyledChip
      onClick={onClick && handleClick}
      label={decodeURI(tagLabel)}
      clickable={!!onClick}
      variant={variant}
      color='primary'
      size='small'
      colorSchema={colorSchema}
      css={tagCSS}
      {...props}
    />
  );
});
